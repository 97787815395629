import SVGIcon from 'components/SVGIcon/SVGIcon';
import React, { FC, useMemo } from 'react';
import useDMCA from 'hooks/useDMCA';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import media from 'utils/mediaStyle';
import { pathnameChecker } from 'utils/url';
import contactUsPageMessages from 'containers/ContactUsPage/messages';
import makeSelectMainLayout from '../../containers/MainLayout/selectors';
import { IMainLayoutStateProps, MainLayoutState, RootState } from '../../containers/MainLayout/types';
import AppStore from './AppStore.svg';
import AppStoreVi from './AppStoreVi.svg';
import BigIcon from './BigIcon.svg';
import DownloadApp from './DownloadApp';
import Facebook from './Facebook.svg';
import GoogleStore from './GoogleStore.svg';
import GoogleStoreVi from './GoogleStoreVi.svg';
import Instagram from './Instagram.svg';
import Linkedin from './Linkedin.svg';
import RegisteredIndustryAndTrade from './RegisteredIndustryAndTrade.svg';
import Youtube from './Youtube.svg';
import Zalo from './Zalo.svg';
import Tiktok from './Tiktok.svg';
import messages from './messages';
import mainNavMessages from '../MainNav/messages';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { generateSlugFromID } from 'utils/urls/categoriesPathSegment';

interface FooterProps extends InjectedIntlProps, RouteComponentProps {
  mainLayout: MainLayoutState;
}

const FooterContainer = styled('div')`
  font-weight: 400;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10pt;
  ${media.md`
    border-radius: 4px;
  `}
`;

const FooterMiddle = styled('div')`
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 30px 0pt 60px;
  font-size: 16px;
  gap: 24px;
  border-bottom: 1px solid #f1f1f1;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const FooterBottomWrapper = styled('div')`
  font-size: 16px !important;
`;
const FooterBottom = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: 16px;
  padding: 20px 10pt 6px;
  font-size: 16px;
  color: #424242;
  margin: 0 auto;
  width: fit-content;
  p {
    text-align: center;
  }
`;

const ContainerBrandIcon = styled.div`
  svg {
    width: 32px;
    height: 32px;

    ${media.md`
      width: 24px;
      height: 24px;
    `}
  }
`;

const IconContainer = styled('div')`
  display: flex;
  width: auto;
  gap: 10px;
`;

const IconSocial = styled(SVGIcon)`
  & > div > .injected-svg {
    width: 36px !important;
    height: 36px !important;
  }
  &:hover {
    transform: scale(1.1);
    transition: 0.9 ease-in-out;
  }
`;

const RegisteredIcon = styled(SVGIcon)`
  & > div {
    justify-content: flex-start;
  }
  & > div > .injected-svg {
    width: 170px !important;
    height: 55px !important;
  }
`;

const FooterParagraph = styled('div')`
  position: relative;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: #424242;
  > a {
    font-weight: 400;
    font-size: 16px;
    color: #424242;
    &:hover {
      color: #43a047;
    }
  }
  ${media.md`
    width: auto;
  `}
`;

const DownloadAppSection = styled.div`
  display: flex;
  gap: 24px;
`;

const AllRightServe = styled.p`
  text-align: right;
  padding: 0 6px;
  position: relative;
  display: block;
  @media (min-width: 768px) {
    &:not(:last-child):before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 60%;
      background: #bdbdbd;
    }
  }
  @media (max-width: 767px) {
    &:first-child {
      grid-area: 2/1/3/3;
    }
  }
  a {
    text-decoration: underline;
  }
`;

const FooterCategoryWrapper = styled.nav`
  padding: 20px 0pt 30px;
  border-bottom: 1px solid #f1f1f1;
  &:after {
    position: initial;
    position: initial;
  }
`;
const FooterParagraphBottom = styled.div`
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  color: #424242;
  & > a {
    margin: 0 4px;
    &:not(:first-of-type) {
      margin-right: 0px;
    }
  }
  &.add-margin {
    margin-top: 4px;
  }
  &.add-padding-bottom {
    padding-bottom: 40px;
  }
`;

const FooterCompanyName = styled.span`
  font-size: 15px;
  line-height: 20px;
  color: #424242;
  font-weight: 500;
`
const FooterCategoryList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 10px;
  column-gap: 40px;
  list-style: none;
  margin-block: 0;
  padding-inline: 0;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const FooterCategoryItem = styled.li``;
const FooterCategoryItemName = styled(Link)`
  color: #595959;
  font-weight: 500;
  font-size: 14px;
`;
const FooterCategorySubItemList = styled.div`
  display: flex;
  align-items: center;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-top: 5px;
`;
const FooterCategorySubItem = styled(Link)`
  padding: 0 8px;
  position: relative;
  font-size: 14px;
  color: #595959;
  &:not(:last-child):before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 1px;
    height: 12px;
    background: #595959;
  }
  &:hover {
    color: #43a047;
  }
`;

const KamereoInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const KamereoContact = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const FooterContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const ContactParagraph = styled.div`
  font-size: 16px;
  color: #424242;
  &.address {
    line-height: 28px;
  }
  &.bold {
    font-weight: 500;
  }
  & > a {
    color: #424242;
    &:hover {
      color: #43a047;
    }
  }
`;

const ContactValue = styled.div`
font-size: 15px;
color: #424242;
&.address {
  line-height: 28px;
}
&.bold {
  font-weight: 500;
}
& > a {
  color: #424242;
  &:hover {
    color: #43a047;
  }
}
`;

const ContactLabel = styled.div`
  padding-left: 8px;
  font-size: 15px;
  color: #424242;
  &.address {
    line-height: 28px;
  }
  &.bold {
    font-weight: 500;
  }
  & > a {
    color: #424242;
    &:hover {
      color: #43a047;
    }
  }
`
const KamereoImage = styled.div`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const KamereoSocial = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  @media (max-width: 992px) {
    text-align: center;
    row-gap: 25px;
    align-items: center;
  }
`;

const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  gap: 24px;
`;

export const DMCA = styled.img`
  max-width: 120px;
  @media (max-width: 992px) {
    max-width: 100px;
  }
`;
const SharingCorner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SocialNetworkGroup = () => {
  return (
    <IconContainer>
      <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/kamereo.vn">
        <ContainerBrandIcon>
          <IconSocial src={Facebook} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
      <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/kamereo.vn">
        <ContainerBrandIcon>
          <IconSocial src={Instagram} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
      <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCHtJ6fCFSNZH0DBC8OqhicA">
        <ContainerBrandIcon>
          <IconSocial src={Youtube} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
      <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/kamereo">
        <ContainerBrandIcon>
          <IconSocial src={Linkedin} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
      <a rel="noopener noreferrer" target="_blank" href="https://zalo.me/2357831519751856428">
        <ContainerBrandIcon>
          <IconSocial src={Zalo} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
      <a rel="noopener noreferrer" target="_blank" href="https://www.tiktok.com/@kamereo.vietnam">
        <ContainerBrandIcon>
          <IconSocial src={Tiktok} imgAttr={{ loading: 'lazy', width: '36px', height: '36px' }} />
        </ContainerBrandIcon>
      </a>
    </IconContainer>
  );
};

const Footer: FC<FooterProps> = (props) => {
  const {
    mainLayout: { categories, breadCrumbDataMarkup },
    location,
  } = props;

  // Set ref for DMCA badge
  const dmcaRef = useDMCA();

  // Only the homepage is set the internal link to the footer's category item
  const isHomePage = pathnameChecker.isHomePage(location.pathname);
  let subCategoryName: string = '';

  // Only the subcategory of visited product page is set the internal link to the footer's category item
  if (!isHomePage) {
    if (breadCrumbDataMarkup) {
      const breadCrumbData = JSON.parse(breadCrumbDataMarkup);
      subCategoryName = breadCrumbData?.itemListElement[2]?.name;
    }
  }

  const lang = window.localStorage.getItem('lang') || 'vi';
  const categoriesData = useMemo(() => {
    const categoriesClone = JSON.parse(JSON.stringify(categories));
    const confectionIndex = categoriesClone.findIndex((item) => item.name === 'Confection');
    if (confectionIndex !== -1) categoriesClone.splice(confectionIndex, 1);
    return categoriesClone;
  }, [categories]);
  return (
    <FooterContainer>
      <FooterCategoryWrapper>
        <FooterCategoryList>
          {categoriesData.map((category) => (
            <FooterCategoryItem key={category.name}>
              <FooterCategoryItemName
                rel={!isHomePage ? 'nofollow' : undefined}
                to={`/${generateSlugFromID(category?.id || 0, lang)}`}
              >
                {category.nameLocal}
              </FooterCategoryItemName>
              <FooterCategorySubItemList>
                {category.children.map((subItem) => (
                  <FooterCategorySubItem
                    key={subItem.nameLocal}
                    to={`/${generateSlugFromID(subItem?.id || 0, lang)}`}
                    rel={!isHomePage && subItem.nameLocal !== subCategoryName ? 'nofollow' : undefined}
                  >
                    {subItem.nameLocal}
                  </FooterCategorySubItem>
                ))}
              </FooterCategorySubItemList>
            </FooterCategoryItem>
          ))}
        </FooterCategoryList>
      </FooterCategoryWrapper>
      <FooterMiddle>
        <KamereoInfo>
          <FooterParagraph>Kamereo.vn</FooterParagraph>
          <FooterParagraph>
            <a rel="noopener noreferrer" href={`https://kamereo.vn/`}>
              <FormattedMessage {...messages.home} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/${lang === 'vi' ? 've-cong-ty' : 'about-company'}`}
            >
              <FormattedMessage {...messages.aboutUs} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/${lang === 'vi' ? 've-kamereo' : 'about-kamereo'}`}
            >
              <FormattedMessage {...messages.service} />
            </a>
          </FooterParagraph>

          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              target="_blank"
              href={`https://kamereo.vn/${lang === 'vi' ? 'tuyen-dung' : 'career'}`}
            >
              <FormattedMessage {...messages.careers} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/${lang === 'vi' ? 'gia-tri-cot-loi' : 'core-values'}`}
            >
              <FormattedMessage {...messages.coreValues} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'tin-tuc-kamereo/' : 'kamereo-news/'}`}
            >
              <FormattedMessage {...messages.news} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'chung-nhan/' : 'certifications/'}`}
            >
              <FormattedMessage {...messages.certifications} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'bao-chi-noi-gi-ve-kamereo/' : 'kamereo-in-the-newspaper/'
                }`}
            >
              <FormattedMessage {...messages.press} />
            </a>
          </FooterParagraph>
        </KamereoInfo>
        <KamereoContact>
          <FooterParagraph>
            <FormattedMessage {...messages.support} />
          </FooterParagraph>
          <FooterContactItem>
            <ContactParagraph>
              <a
                rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
                href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'hinh-thuc-thanh-toan-tai-kamereo/' : 'payment-methods-at-kamereo/'
                  }`}
              >
                <FormattedMessage {...messages.payment} />
              </a>
            </ContactParagraph>
          </FooterContactItem>
          <FooterContactItem>
            <ContactParagraph>
              <a
                rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
                href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'chinh-sach-giao-hang/' : 'delivery-policy/'}`}
              >
                <FormattedMessage {...messages.delivery} />
              </a>
            </ContactParagraph>
          </FooterContactItem>
          <FooterContactItem>
            <ContactParagraph>
              <a
                rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
                href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'chinh-sach-doi-tra-hang-tai-kamereo/' : 'exchange-return-policy-at-kamereo/'
                  }`}
              >
                <FormattedMessage {...messages.exchange} />
              </a>
            </ContactParagraph>
          </FooterContactItem>
          <FooterContactItem>
            <ContactParagraph>

              <FormattedMessage {...messages.consultingServices} />

            </ContactParagraph>
          </FooterContactItem>
          <FooterContactItem>
            <ContactLabel>
              <FormattedMessage {...messages.phoneNumber} />
            </ContactLabel>
            <ContactValue className="bold">0812 46 37 27</ContactValue>
          </FooterContactItem>
          <FooterContactItem>
            <ContactLabel>Email:</ContactLabel>
            <ContactValue className="bold">info@kamereo.vn</ContactValue>
          </FooterContactItem>
          <FooterContactItem>
            <ContactParagraph>

              <FormattedMessage {...messages.reflectingQuality} />
            </ContactParagraph>
          </FooterContactItem>
          <FooterContactItem>
            <ContactLabel>
              <FormattedMessage {...messages.phoneNumber} />
            </ContactLabel>
            <ContactValue className="bold">0812 46 37 27</ContactValue>
          </FooterContactItem>
          <FooterContactItem>
            <ContactLabel>Email:</ContactLabel>
            <ContactValue className="bold">info@kamereo.vn</ContactValue>
          </FooterContactItem>

        </KamereoContact>
        <SharingCorner>
          <FooterParagraph>
            <FormattedMessage {...mainNavMessages.sharingConner} />
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'kien-thuc-kinh-doanh/' : 'business-tips/'}`}
            >
              <FormattedMessage {...messages.businessTips} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'am-thuc-doi-song/' : 'food-and-lifestyle/'}`}
            >
              <FormattedMessage {...mainNavMessages.foodAndLifeStyle} />
            </a>
          </FooterParagraph>
          <FooterParagraph>
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'dia-diem-an-uong-vui-choi/' : 'place-to-eat-drink-entertain/'
                }`}
            >
              <FormattedMessage {...mainNavMessages.topPlaces} />
            </a>
          </FooterParagraph>
          <FooterContactItem>
            <ContactParagraph>
              <a
                rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
                href={`https://kamereo.vn/blog/${lang}/${lang === 'vi' ? 'tu-nong-trai-den-cua-hang/' : 'from-farm-to-business/'
                  }`}
              >
                <FormattedMessage {...messages.commitFresh} />
              </a>
            </ContactParagraph>
          </FooterContactItem>
        </SharingCorner>
        <KamereoImage>
          <SVGIcon src={BigIcon} imgAttr={{ loading: 'lazy', width: '280px', height: '160px' }} />
          <DownloadAppSection>
            <DownloadApp
              icon={
                <SVGIcon
                  src={lang === 'vi' ? AppStoreVi : AppStore}
                  imgAttr={{ loading: 'lazy', width: '126px', height: '39px' }}
                />
              }
              link="https://apps.apple.com/sa/app/kamereo/id1469505654"
              platform="Apple Store"
            />
            <DownloadApp
              icon={
                <SVGIcon
                  src={lang === 'vi' ? GoogleStoreVi : GoogleStore}
                  imgAttr={{ loading: 'lazy', width: '126px', height: '39px' }}
                />
              }
              link="https://play.google.com/store/apps/details?id=vn.kamereo.buyer&hl=en_US&gl=US"
              platform="Google Play Store"
            />
          </DownloadAppSection>
        </KamereoImage>
        <KamereoSocial>
          <FooterParagraph>
            <FormattedMessage {...messages.followUs} />
          </FooterParagraph>
          <SocialNetworkGroup />
          <BadgesWrapper>
            {/* <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              target="_blank"
              href="http://online.gov.vn/Home/WebDetails/44606"
            >
              <RegisteredIcon
                src={RegisteredIndustryAndTrade}
                imgAttr={{ loading: 'lazy', width: '170px', height: '55px' }}
              />
            </a> */}
            <a
              rel={`noopener noreferrer ${!isHomePage ? 'nofollow' : undefined}`}
              ref={dmcaRef}
              target="_blank"
              href="//www.dmca.com/Protection/Status.aspx?ID=ac07edbf-327a-41ad-8737-603222e24d2d"
              title="DMCA.com Protection Status"
              className="dmca-badge"
            >
              <DMCA
                src="https://images.dmca.com/Badges/dmca-badge-w150-2x1-04.png?ID=ac07edbf-327a-41ad-8737-603222e24d2d"
                alt="DMCA.com Protection Status"
                width="120px"
                height="60px"
              />
            </a>
          </BadgesWrapper>
        </KamereoSocial>
      </FooterMiddle>
      <FooterBottomWrapper>
        <FooterBottom>
          <AllRightServe>
            <FormattedMessage {...messages.allRightReserved} />
          </AllRightServe>
          <AllRightServe>
            <a target="_blank" href={`${process.env.APP_URL}/docs/term-of-service`} rel="nofollow">
              <FormattedMessage {...contactUsPageMessages.terms} />
            </a>
          </AllRightServe>
          <AllRightServe>
            <a target="_blank" href={`${process.env.APP_URL}/docs/privacy-policy`} rel="nofollow">
              <FormattedMessage {...contactUsPageMessages.privacyCondition} />
            </a>
          </AllRightServe>
        </FooterBottom>
        <FooterParagraphBottom>
          <FormattedMessage {...messages.firstFooterDescription} />
          <Link to="/market?category=Veggies" rel={isHomePage ? 'nofollow' : 'follow'}>
            <FormattedMessage {...messages.freshVegetables} />
          </Link>
          <FormattedMessage {...messages.to} />
          <Link to="/market?category=Non-food" rel={isHomePage ? 'nofollow' : 'follow'}>
            <FormattedMessage {...messages.kitchenUtensils} />
          </Link>
          <FormattedMessage {...messages.lastFooterDescription} />
        </FooterParagraphBottom>
      </FooterBottomWrapper>
      <FooterParagraphBottom className="add-margin">
       <FooterCompanyName><FormattedMessage {...messages.kamereoCo} /></FooterCompanyName>  <FormattedMessage {...messages.businessRegistrationCertificate} />
      </FooterParagraphBottom>
      <FooterParagraphBottom className="add-margin">
        <FormattedMessage {...messages.address} />
      </FooterParagraphBottom>
      <FooterParagraphBottom className="add-margin add-padding-bottom">
        <FormattedMessage {...messages.wholesaleLiquorLicense} />
      </FooterParagraphBottom>
    </FooterContainer>
  );
};

const mapStateToProps = createStructuredSelector<
  RootState,
  Omit<IMainLayoutStateProps, 'isPenddingApproval' | 'countPendingOrder'>
>({
  mainLayout: makeSelectMainLayout(),
});

const withConnect = connect(mapStateToProps);

export default compose(injectIntl, withConnect, withRouter)(Footer);
